<template>
  <div class="warp">
    <div class="item-list" v-for="(item, index) in itemList" :key="index">
      <div class="left">
        <van-radio-group v-model="radio" icon-size="27px">
          <van-radio :name="item.id"></van-radio>
        </van-radio-group>
      </div>
      <div class="center" @click="assignmentClick(item)">{{item.orderAddress + ' ' + '(' + item.storeName + ')'}}</div>
      <div class="right" @click="editClick(item)">编辑</div>
    </div>
    <div class="btn">
      <van-button round block type="info" @click="addClick">添加新的清运地址</van-button>
      <van-button round block type="default" @click="goBackClick">返回</van-button>
    </div>
  </div>
</template>

<script>
import { addresslist } from '@/api/notice.js'
export default {
  data () {
    return {
      radio: undefined,
      itemList: [], // 保存地址list
    }
  },
  created() {
    this.addresslist()
  },
  beforeRouteLeave (to, from, next) {
    if (to.path === '/Placeanorder') {
      to.meta.keepAlive = true
    } else {
      to.meta.keepAlive = false
    }
    next()
  },
  methods: {
    addresslist() { // 获取地址list
      addresslist().then(res => {
        if (res.data.code === 200) {
          this.itemList = res.data.rows
          this.itemList.forEach(item => {
            if (item.isDefault === 1) {
              this.radio = item.id
            }
          })
        }
      })
    },
    assignmentClick(item) { // 选择地址赋值
      if (this.$route.query.order) {
        this.$router.push({ path: '/Placeanorder', query: {address: item.orderAddress, addressId: item.id} })
      }
    },
    addClick() { // 添加地址
      this.$router.push({path:'Addaddress'})
    },
    editClick(item) { // 编辑地址
      this.$router.push({path:'Addaddress', query: {editId: item.id}})
    },
    goBackClick() {
      this.$router.go(-1)
    }
  }
}

</script>
<style lang="scss" scoped>
.warp {
  .item-list {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background-color: #fff;
    .left, .right {
      text-align: center;
      color: #0087f0;
      padding: 23px;
      font-size: 18px;
    }
    .center {
      flex: 1;
    }
  }
}
::v-deep .van-button--round {
  border-radius: 3px;
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
  margin-bottom: 15px;
}
</style>
